<template>
  <div class="cmmJxGoodsDetails">
    <van-swipe  :autoplay="5000" lazy-render>
      <van-swipe-item v-for="image in info.images" :key="image">
        <img :src="image" />
      </van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-grid :column-num="4" :border="false">
          <van-grid-item>
            <ul>
              <li>高佣比例</li>
              <li>{{info.activity_cos_ratio}}% <span>公开 {{info.kol_cos_ratio}}%</span></li>
            </ul>
          </van-grid-item>
          <van-grid-item>
            <ul>
              <li>预估每单赚</li>
              <li>￥{{(info.price * (info.activity_cos_ratio/100) ).toFixed(2)}}</li>
            </ul>
          </van-grid-item>
          <van-grid-item>
            <ul>
              <li></li>
            </ul>
          </van-grid-item>
          <van-grid-item>
            <ul>
              <li></li>
              <li></li>
              <li>总销量{{info.sales>10000?(info.sales/10000).toFixed(2)+'W':info.sales}}</li>
            </ul>
          </van-grid-item>
        </van-grid>

      <van-divider  :style="{margin: '0 2% 2% 2%'}"/>

      <div class="info_title">{{info.title}}</div>

      <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <div class="info_price">
              到手价  ￥<b>{{info.price.toFixed(2)}}</b>
            </div>
          </van-grid-item>
          <van-grid-item>
            <div class="info_price">
              库存<b>{{stockNum>10000?(stockNum/10000).toFixed(2)+'W':stockNum}}</b>
            </div>
          </van-grid-item>
          <van-grid-item>
            <div class="info_price">
              <van-button v-on:click="showSku()" color="red" size="mini" plain>&nbsp;规格 ＞</van-button>
            </div>
          </van-grid-item>
      </van-grid>

    <van-grid :column-num="1" :border="false" direction="horizontal" :center="false">
          <van-grid-item class="shop_info">
            <img class="shop_icon" :src="info.shop_icon==''?'http://img.lchuning.com/img/login.png':info.shop_icon"/>
            <span style="font-size: small;padding-top:4%">{{info.shop_name}}丨商家体验分{{info.expr_score.toFixed(2)}}
              <van-tag v-if="info.expr_level==1" type="success">高</van-tag>
              <van-tag v-if="info.expr_level==2" type="primary">中</van-tag>
              <van-tag v-if="info.expr_level==3" type="warning">低</van-tag>
              <van-tag v-if="info.expr_level==4" type="danger">差</van-tag>
            </span>
          </van-grid-item>
      </van-grid>
    </div>
    <div class="info score">
        <van-grid :column-num="1" :border="false" direction="horizontal" :center="false">
          <van-grid-item>
            <div></div>
          </van-grid-item>
          <van-grid-item>
            <div>购物初体验</div>
          </van-grid-item>
          <van-grid-item>
                  商家体验分：<van-rate v-model="info.expr_score" readonly allow-half/>
                  &nbsp;
                  <van-tag v-if="info.expr_level==1" type="success">高</van-tag>
                  <van-tag v-if="info.expr_level==2" type="primary">中</van-tag>
                  <van-tag v-if="info.expr_level==3" type="warning">低</van-tag>
                  <van-tag v-if="info.expr_level==4" type="danger">差</van-tag>
          </van-grid-item>
          <van-grid-item>
                  商品体验分：<van-rate v-model="info.desc_score" readonly allow-half/>
                  &nbsp;
                  <van-tag v-if="info.desc_level==1" type="success">高</van-tag>
                  <van-tag v-if="info.desc_level==2" type="primary">中</van-tag>
                  <van-tag v-if="info.desc_level==3" type="warning">低</van-tag>
                  <van-tag v-if="info.desc_level==4" type="danger">差</van-tag>
          </van-grid-item>
          <van-grid-item>
                  物流体验分：<van-rate v-model="info.post_score" readonly allow-half/>
                  &nbsp;
                  <van-tag v-if="info.post_level==1" type="success">高</van-tag>
                  <van-tag v-if="info.post_level==2" type="primary">中</van-tag>
                  <van-tag v-if="info.post_level==3" type="warning">低</van-tag>
                  <van-tag v-if="info.post_level==4" type="danger">差</van-tag>
          </van-grid-item>
          <van-grid-item>
                  商家服务分：<van-rate v-model="info.serv_score" readonly allow-half/>
                  &nbsp;
                  <van-tag v-if="info.serv_level==1" type="success">高</van-tag>
                  <van-tag v-if="info.serv_level==2" type="primary">中</van-tag>
                  <van-tag v-if="info.serv_level==3" type="warning">低</van-tag>
                  <van-tag v-if="info.serv_level==4" type="warning">差</van-tag>
          </van-grid-item>
      </van-grid>
    </div>
    <div class="sell_point" v-if="info.sell_point!=''">
      <h5>商品亮点</h5>
      {{info.sell_point}}
    </div>
    <van-divider>商品详情</van-divider>
    <div class="div_info_detail_images">
      <van-image class="info_detail_images" :key="key"
        v-for="image,key in info.detail_images"
        lazy-load
        :src="image"
      />
      <img class="info_detail_images"
        v-show="info.detail_images.length==0"
        src="../assets/none.png"
      />
    </div>
    <div class="price_detail">
      <h5>价格说明</h5>
      <h6>售卖价，由商家依照《价格法》规定的定价原则及定价依据通过商家后台页面自主填写 (适用政府指导价或者政府定价除外)，如商品未通过商家后台参加促销活动(如优惠券满减、秒杀、拼团等)的，售卖价将作为销售价格展示。</h6>
      <h6>如商品通过商家后台参加促销活动(如优惠券、满减、秒杀、拼团等)，售卖价将会作为被比较价格，可能以“划线价(即价格上划横线)”、“参考价”等形式展示，此时售卖价作为被比较的价格，并非原价，仅用于向消费者展示商品未参与促销活动的价格。</h6>
      <h6>到手价，指商品使用各种优惠券、参与满减等促销活动后消费者实际支付的价格。到手价可能因促销活动、达人主播专享、补贴、特殊时段等各类因素而产生变动，最终以消费者在商品订单中的支付金额为准。</h6>
      <h6>商品价格由商家自主决定，如商家及/或达人对商品价格有特别说明的，以商家/达人表述为准。平台依据法律法规及平台规则，坚决打击各类价格违法行为，如消费者发现价格违法情形的，请及时向平台投诉举报。</h6>
    </div>


    <van-action-sheet v-model:show="skuShow" :cancel-text="'关闭'" closeable :title="info.shop_name">
      <div class="sku-content">
          <div class="noScroll">
            <van-card
              :price="(info.skus[skuSpec].effective_price/100).toFixed(2)"
              :title="info.title"
              :thumb="info.cover"
            >
              <template #desc>
                库存<nut-price :price="info.skus[skuSpec].stock_num" :decimal-digits="0" thousands :need-symbol="false" size="small"/>
              </template>
              <template #origin-price>
                <van-tag  class="sku-content-info-tag">预计每单赚</van-tag><nut-price :price="((info.skus[skuSpec].effective_price/100) * (info.activity_cos_ratio/100)).toFixed(2)"  />
              </template>
              
            </van-card>

            <van-divider/>
          </div>

          <div class="sku-content-info">
              <van-cell-group :title="obj.name" v-for="obj,key in info.spec" :key="key" >
              <van-cell v-for="o,k in obj.spec_items" :key="k" :border="false">
                  <template #title>
                    <van-tag v-on:click="selectSku(key,k)" :class="{'sku-content-info-tag':sku[key] != k,'sku-content-info-tag-active':sku[key] == k}">{{o.name}}</van-tag>
                  </template>
              </van-cell>
            </van-cell-group>
          </div>

      </div>
    </van-action-sheet>
    <van-action-bar>
      <van-action-bar-icon icon="share-o" text="分享赚" v-on:click="shareLink()"/>
      <van-action-bar-icon v-on:click="copyLink()">
              <template #icon>
                <nut-icon size="16"  :name="require('../assets/icon/iconCopy.png')"></nut-icon>
              </template>
              <template #default>
                复制链接
              </template>
      </van-action-bar-icon>
      <van-action-bar-icon v-on:click="openLink()">
        <template #icon>
          <nut-icon size="16"  :name="require('../assets/icon/browser.png')"></nut-icon>
        </template>
        <template #default>
          打开链接
        </template>
      </van-action-bar-icon>
      <van-action-bar-button type="danger" text="成本购样" v-on:click="showCostshopping()"/>
    </van-action-bar>

    <van-popup v-model:show="costshopping" class="popup-costshopping" position="bottom" closeable :style="{ height: '100%' }" >
        <div class="costshopping">
          <van-card
          :price="(info.skus[skuSpec].effective_price/100).toFixed(2)"
          :title="info.title"
          :thumb="info.cover">
          <template #origin-price>
            <van-tag  class="sku-content-info-tag">预计返还金额</van-tag><nut-price :price="(info.price * (info.activity_cos_ratio/100) ).toFixed(2)"  />
          </template>
        </van-card>
        <div class="wrring">
          <van-cell-group>
            <van-cell size="large">
              <template #value>
                <div style="font-size: 10px;margin: 0 0 0 5px;">样品订单结算后，自动返还到零钱，可申请提现</div>
              </template>
              <template #icon>
                <div style="transform: translate(0px, 2px);">
                  <nut-icon size="16"  :name="require('../assets/icon/waring.png')"></nut-icon>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
        <van-cell-group title="购买方式一">
          <van-cell title="复制口令打开抖音APP下单" :border="false" :label="shareProductLink.dk_password"/>
          <van-cell>
            <div class="popup-van-button">
              <van-button size="small" hairline plain round type="danger"  v-on:click="copyPassword()">复制口令</van-button>
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell-group title="购买方式二">
          <van-cell title="抖音APP扫码下单" :border="false"/>
          <van-cell   :border="false" >
            <div  ref="dycode2">
              <van-grid :border="false" :column-num="2">
                <van-grid-item>
                  <van-image :src="shareProductLink.dk_code_img"/>
                </van-grid-item>
                <van-grid-item>
                  <van-image :src="require('../assets/saveing2.png')" />
                </van-grid-item>
              </van-grid> 
            </div>
          </van-cell>
          <van-cell  :border="false" >
            <div class="popup-van-button">
              <van-button size="small" hairline plain round type="danger"  v-on:click="saveImage('dycode2')">保存图片</van-button>
            </div>
          </van-cell>
        </van-cell-group>

        </div>
    </van-popup>

    <van-dialog v-model:show="shareSheet" title="分享赚" show-cancel-button>
      <van-tabs v-model:active="active">
        <van-tab title="抖音口令">
          <div class="dk_password">
            <div>【{{info.shop_name}}】{{info.title}}</div>
            <div>【售价】{{info.price}}元</div>
            <div>【操作】复制下面口令打开抖音APP下单</div>
            <hr>
            <div>{{shareProductLink.dk_password}}</div>
            <div>
              <van-grid :column-num="2" :border="false">
                <van-grid-item>
                  <div ref="content">
                    <img :src="info.cover" :alt="info.title">
                  </div>
                </van-grid-item>
                <van-grid-item>
                  <van-button size="small" round color="linear-gradient(to right, rgb(131 131 211), rgb(0 16 233))" v-on:click="copyPassword()">
                    复制口令
                  </van-button>
                  <div style="height: 20px;"></div>
                  <van-button size="small" round color="linear-gradient(to right, #ff6034, #ee0a24)" v-on:click="saveImage('content')">
                    保存图片
                  </van-button>
                </van-grid-item>
              </van-grid>
            </div>
          </div>
        </van-tab>
        <van-tab title="抖音码图">
          <div ref="dycode" style="background-color: #fff;">
            <div class="dycode">
               <img :src="info.cover" :alt="info.title">      
            </div>
            <div>
              <van-grid :border="false" :column-num="2" :center="false">
                <van-grid-item>
                  <div class="dy-code-info">
                    <div>{{info.title}}</div>
                    <div style="color:red">￥{{info.price}}</div>
                  </div>
                </van-grid-item>
                <van-grid-item>
                  <img :src="shareProductLink.dk_code_img" :alt="info.title">
                </van-grid-item>
              </van-grid>
            </div>
            <img :src="require('../assets/saveing.png')" :alt="info.title">
          </div>
          <div style="margin: 5px;">
            <van-button round color="linear-gradient(to right, #ff6034, #ee0a24)" block v-on:click="saveImage('dycode')">保存图片</van-button>
          </div>
        </van-tab>
      </van-tabs>
    </van-dialog>
  </div>
</template>
<script>

import html2Canvas from 'html2canvas';

export default {
  name: 'CmmJxGoodsDetails',
  data(){
    return {
      info:{
        price:0.00,
        detail_images:[],
        skus:[],
        shop_icon:"",
        expr_score:0.00,
        title:""
      },
      skuShow:false,
      sku:[],
      skuSpec:'',
      active:'home',
      shareSheet:false,
      shareProductLink:undefined,
      costshopping:false
    }
  },
  created(){
      if(this.$route.query.goodsId==undefined){
          window.history.go(-1);
        return;
      }
      this.axios.post('/app/canmama/jxGoodsDetails', {
                  goodsId:this.$route.query.goodsId,
            }).then((response) => {
              if(response==500){
                return;
              }
              this.info = response;
              console.log(this.info);
              for(let key in this.info.spec){
                this.sku.push(0);
              }
              let o = [];
              for(let key in this.sku){
                  o.push(this.info.spec[key].spec_items[this.sku[key]].id);
                  this.skuSpec = o.join(';');
              }
              
            }).catch(function (error) {
                console.log(error);
            });
  },
  methods:{
    copyPassword(){
      let dk_password = this.shareProductLink.dk_password;
      const save = function(e){
        e.clipboardData.setData( 'text/plain',dk_password);
        e.preventDefault();
      }
      document.addEventListener('copy',save) // 添加一个copy事件
      document.execCommand('copy') // 执行copy方法
        this.$dialog({
          title: '抖口令复制完成,如未成功请手动复制!',
          message: dk_password,
        }).then(() => {
          console.log("关闭");
        });
    },
    saveImage(sole){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      console.log('保存图片');
      let ref = this.$refs[sole] // 截图区域

      html2Canvas(ref, {
        backgroundColor: '#e8f4ff',
        useCORS: true // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png')
        const creatDom = document.createElement('a')
        document.body.appendChild(creatDom)
        creatDom.href = dataURL
        creatDom.download = this.info.shop_name + sole
        creatDom.click();
        this.$dialog({
          title: '图片保存完成!',
          message: '请至相册查看!',
        }).then(() => {
          console.log("关闭");
        });
      })
      
      return false;
      
    },
    openLink(){
      window.location.href = this.info.detail_url;
    },
    copyLink(){
      let detail_url = this.info.detail_url;
      const save = function(e){
        e.clipboardData.setData( 'text/plain',detail_url);
        e.preventDefault();
      }
      document.addEventListener('copy',save) // 添加一个copy事件
      document.execCommand('copy') // 执行copy方法
        this.$dialog({
          title: '链接复制完成,如未成功请手动复制!',
          message: detail_url,
        }).then(() => {
          console.log("关闭");
        });
    },
    shareLink(){
        this.shareSheet = true;
        if(this.shareProductLink!=undefined){
          return;
        }
        this.getShareProductLink();
    },
    showCostshopping(){
        this.costshopping = true;
        if(this.shareProductLink!=undefined){
          return;
        }
        this.getShareProductLink();
    },
    getShareProductLink(){
      this.shareProductLink = {};
        this.axios.post('/app/canmama/shareProductLink', {
                  productId:this.$route.query.goodsId,
            }).then((response) => {
              console.log(response);
              this.shareProductLink = response;
            }).catch(function (error) {
                console.log(error);
            });
    },
    showSku(){
      this.skuShow = true;
    },
    selectSku(key,k){
      let sku = [...this.sku];
      sku[key] = k;
      let skuSpec = this.skuSpec;
      let o = [];
      for(let key in sku){
          o.push(this.info.spec[key].spec_items[sku[key]].id);
          skuSpec = o.join(';');
      }
      if(this.info.skus[skuSpec] == undefined){
        this.$toast.loading({
          message: '补货中...',
          forbidClick: true,
          duration:1000
        });

        return false;
      }else{
        this.sku[key] = k;
        this.skuSpec = skuSpec;
      }
      return true;
    }
  },
  computed:{
    stockNum(){
      let stock_num = 0;
      for (let key in this.info.skus) {
          stock_num += this.info.skus[key].stock_num;
      }
      return stock_num;
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height:100%;
}
.cmmJxGoodsDetails .info {
  margin: 1%;
  background: #fff;
}
.info li:first-child {
  color: red;
  font-size: 10px;
  /* font-weight: 700; */
}
.info li:nth-child(2) {
  color: red;
  font-size: 15px;
  font-weight: 700;
  margin-top: 3%;
}
.info li span {
  font-size: 8px;
  color: rgb(78, 78, 78);
}
.info li:nth-child(3) {
  color: rgb(104, 104, 104);
  font-size: 15px;
}
.info_title {
  margin: 3%;
  /* letter-spacing:1px; */
}
.info_price {
  margin: 3%;
  font-size: 8px;
}
.info_price b {
    font-size: 16px;
  }
.div_info_detail_images {
  background: #fff;
}
div .info_detail_images {
  margin-top: 0%;
  display: block;
}
.sku-content {
  --van-action-sheet-max-height:20%;
  --van-card-background:#fff;
  --van-card-background-color	:#fff;
  overflow: hidden;
}
.noScroll {
  height: 121px;
}
.sku-content-info {
  height: 300px;
  overflow:scroll;
  --van-cell-group-title-color:rgb(0, 0, 0);
}
::-webkit-scrollbar {
  width: 0px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #fff;
  width: 0px;
}
.sku-content-info-tag {
  background-color:rgb(235 235 235);
  color:rgb(117 117 117);
}
.sku-content-info-tag-active {
  background-color:rgb(251 220 175);
  color:rgb(255 85 0);
}
.shop_icon {
  width: 10%;
  border-radius: 100%;
}
.sell_point {
  margin: 1%;
  padding: 5px;
  background-color: #fff;
  border-radius: 5%;
  white-space: pre-line;
}  
.price_detail {
  margin: 1%;
  padding: 5px 5px 10% 5px;
  background-color: #fff;
  border-radius: 5%;
  white-space: pre-line;
}
.shop_info {
  --van-card-background-color	: #fff;
  --van-grid-item-content-padding:0% 2% 5%;
}
.score {
  --van-grid-item-content-padding:0% 3% 5%;
}
.dk_password {
  margin: 5%;
}
.dycode {
  height: 150px;
  width: 100%;
  background-color: #fff;
  background: url('../assets/dou.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.dycode img {
    width: 100px;
    height: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50px, 25%);
    border-radius: 10%;
}

.dy-code-info div {
  margin-top: 10px;
}
.costshopping {
  margin-top: 15%;
  background-color: #fff;
  --van-card-background-color: #fff;
}
.popup-costshopping {
  --van-popup-background-color:#f5f5f5;
}
.popup-van-button {
  height: 100%;
  width: 100%;
  text-align: right;
}
</style>
